


import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MiscUtil } from '../../../util/misc.util';

import { DATAID } from '../../../services/data.service';
import { BtEvent } from '../../../services/event.service';

declare let Dibs: any;
declare let Stripe: any;

import { inject } from '@angular/core';

import { LibModulesWolcModule } from '../../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../../services/booktech-app.service";


@Component({
  selector: 'bt-payment',
  standalone: true,
  imports: [ LibModulesWolcModule ],
  templateUrl: './bt-payment.component.html',
  styleUrl: './bt-payment.component.scss'
})
export class BtPaymentComponent {
  bas = inject(BooktechAppService);

  opts:any = {
    bid: undefined,
    test: true,
    showGiftCardButton: true,
    showSumTable: true,
    showCustomAmount: false,
    fixedAmount: undefined,
    useBtapi: false,
    useStripe: false,
    payNow: false,
  }
  data:any = {
    view: "default",
    minAmount: 0,
    customAmount: undefined,
    step: 1,
    loading: false
  }
  b:any = undefined;
  i:any = undefined;

  stripe:any = undefined;

  @Input()   
  get options() { return this.opts; }
  set options(opts: any) {
    if (opts == undefined) return; 

    MiscUtil.extend(this.opts, opts);

    this.update();
  }
  @Output() change = new EventEmitter<any>();

  constructor() {

  }

  ngOnInit(): void {
    if(this.bas.envtest) console.log("BtPaymentComponent.ngOnInit, opts: ", this.opts);

    if (this.opts.useBtapi) {

      if(this.bas.envtest) console.log("BtPaymentComponent.ngOnInit.useBtapi, qps: ", this.bas.ds.getValue(DATAID.APP_URL_QUERY_PARAMS));

      let payId = this.bas.ds.getValue(DATAID.APP_URL_QUERY_PARAMS).paymentId;
      if (payId) {
        //TODO: ta bort fra URL-en på parent-siden?
        this.data.paymentId = payId;
        this.onCompleted(payId);
        return;
      }


      this.bas.es.on(BtEvent.MESSAGE, (ev:any) => {
        
        if(this.bas.envtest) console.log("BtPaymentComponent.onMessage, ev: ", ev);

        if (ev.action == "payment-completed") {
          //TODO: show loading
          this.onCompleted(ev.params.response.paymentId);
        } else    if (ev.action == "payment-cancel") {
          
          this.data.paymentId = undefined;
          this.bas.ui.modalError("Feil", "Betaligen ble ikke fullført, vennligst prøv igjen.");
        }

      });

    } else {

      if (this.opts.useStripe) {
        this.bas.script.loadScript({ 
          name: "stripe-js", 
          src: "https://js.stripe.com/v3/" 
        }).then((info:any) => {
          if(this.bas.envtest) console.log("BtPaymentComponent.loadScript.stripe.then: ", info);
    
    

        });

      } else {

        this.bas.script.loadScript({ 
          name: "dibs-easy", 
          src: "https://"+(this.bas.envprod ? '' : 'test.')+"checkout.dibspayment.eu/v1/checkout.js?v=1" 
        }).then((info:any) => {
          if(this.bas.envtest) console.log("BtPaymentComponent.loadScript.easy.then: ", info);
    
        });
        
      }

    }


  }


  async update() {
    if(this.bas.envtest) console.log("BtPaymentComponent, update, opts: ", this.opts);

    if (this.opts.booking) {
      let opts = this.opts;
      let d = this.data;
      this.b = this.opts.booking;
      this.i = this.b.currentInvoice;

      let remaining = this.b.remainingAmountIncludePendingDouble;

      d.customAmount = remaining;

      d.hasApInvoice = !this.b.isApInvoicePaid && (this.b.findDepositAvailable); // this.b.hasApInvoice || 
      d.depositAmount = this.i?.amountApDouble || this.b.findDepositAmountAsDouble;
      d.step = 
        Number.isInteger(remaining) 
        && (!d.hasApInvoice || Number.isInteger(d.depositAmount)) 
        ? 1 : 0.01
      ;



      if (remaining > 0) {
        d.minAmount = Math.min(100, remaining);

        if (this.b.paidAmountIncludePending == 0 && d.hasApInvoice && this.opts.showCustomAmount != true) {
          d.minAmount = d.depositAmount;
        }

        if (opts.fixedAmount) {
          let ca = MiscUtil.parseNumber(opts.fixedAmount);
          if (MiscUtil.notNan(ca) && ca >= 1 && ca <= remaining) {
            d.customAmount = ca;
          }
      
        }
      }

      if (opts.showCustomAmount == true) d.view = "customAmount";
      else if (opts.fixedAmount) d.view = "fixedAmount";
      else if (d.hasApInvoice) d.view = "apInvoice";
      else d.view = "default";

      if (opts.payNow) {
        this.pay('all');
      }

    } else if (this.opts.bid) {
      let bid = this.opts.bid; // int eller string

      let json = await this.bas.ws.json( { 
        actionType: "order", 
        action: "getBookingPayment", 
        bid:  bid,
      }, { showLoading: true }); 
      
      if (json.success) {
        this.opts.booking = json.booking;
        this.update();
      }

    }
  }

  

  async useGiftcardCode() {
    if(this.bas.envtest) console.log("BtPaymentComponent, useGiftcardCode, value: ", this.data.useGiftCartValue);

    this.data.useGiftCartLoading = true;


    let json = await this.bas.ws.json( { 
      actionType: "order", 
      action: "useGiftCard", 
      bid:  this.b.id,
      giftCardCode: this.data.useGiftCartValue,
     
    }, { showLoading: true }); 
    
    this.data.useGiftCartLoading = false;

    if (this.bas.envtest) console.log("BtPaymentComponent.useGiftcardCode.then, ", json);
    
    if (json.success) {

      this.data.useGiftCartVisible = false;
      this.data.useGiftCartValue = "";

      this.opts.booking = json.booking;
      this.update();
    }

  }



  dibsLangs:any = { "no": "nb-NO", "en": "en-GB", "sv": "sv-SE" };
  initEasyCheckout() {

    let lang = this.bas.ds.findLang();


    if (this.bas.envtest) console.log("BtPaymentComponent.initEasyCheckout.lang: " + lang + ",  dibsLangs: " + this.dibsLangs[lang]);

		var checkoutOptions:any = {
			checkoutKey: this.data.checkoutKey,
			paymentId : this.data.paymentId, 					// [required] GUID without dashes
			//partnerMerchantNumber: "123456789", 	// [optional] Number
			containerId : "dibs-checkout-content", 			// [optional] defaultValue: dibs-checkout-content
			language: this.dibsLangs[lang] 		// [optional] defaultValue: en-GB
				
		};
    
    var bg = false;
    var fg = false;
    
    
    if (bg && fg) {
      checkoutOptions.theme = {
        textColor: fg,          // any valid css color
        linkColor: fg,          // any valid css color
        panelTextColor: fg,     // any valid css color
        panelLinkColor: fg,     // any valid css color
        primaryColor: fg,       // any valid css color
        buttonRadius: "50px",   // pixel or percentage value
        buttonTextColor: bg,    // any valid css color
        backgroundColor: bg,    // any valid css color
        panelColor: bg,         // any valid css color
        outlineColor: fg,       // any valid css color
        primaryOutlineColor: fg // any valid css color   
      }
    }


    if (this.opts.useBtapi) {

      if(this.bas.envtest) console.log("BtPaymentComponent.easyPay.opts.useBtapi");

      
      
      this.bas.es.sendParentMessage({
        action: "payment",
        checkoutKey: checkoutOptions.checkoutKey,
        paymentId: checkoutOptions.paymentId,
        dibsLang: checkoutOptions.language

      });


      return;
    }



		var checkout = new Dibs.Checkout(checkoutOptions);

		checkout.on('payment-completed', (response:any) => {
			//$cb.debug("response: ", response);
      if(this.bas.envtest) console.log("BtPaymentComponent.payment-completed: ", response);

      checkout.cleanup();
      
			//$cb.redirect($cb.data.baseWebUrl + "/order/paymentComplete.htm?paymentId=" + response.paymentId);  // /order/paymentComplete.htm?paymentId=xxxx
      this.onCompleted(response.paymentId);
			
		});
		
    // TODO: sende en event slik at /checkout siden kan endre URL til /payment 

	}

  onCompleted(paymentId:string) {

    if (this.data.onCompleted) {
      if (this.bas.envtest) console.log("BtPaymentComponent.onCompleted, data.onCompleted == true");
      return;
    }

    this.data.onCompleted  = true;
    this.data.loading  = true;

    this.bas.ws.json( { 
      actionType: "order", 
      action: "easyPayComplete", 
      paymentId: paymentId,
    }, { showLoading: true }).then((json) => {
      
      this.data.loading  = false;
      this.data.onCompleted  = false;
      if (this.bas.envtest) console.log("BtPaymentComponent.onCompleted.then, ", json);
      
      if (json.success) {

        if (!json.det || json.isProcessing) return; // Kan skje hvis det er dobbel registering

        // TODO: vise info'  
        this.data.det = json.det;
        
        this.data.completed = true;
        // this.data.paymentId = undefined;

        if (!json.alreadyCompleted) {
          this.bas.ui.success(this.bas.ui.actrans("app.lib.component.payment.success") );
        }

        this.change.next({ 
          "type": "completed", 
          det: json.det,
          booking: json.booking || this.b
        });
        

      }

      return json;
    }, (err) => {
      
      this.data.loading  = false;
      this.data.onCompleted  = false;
    });
  }

  // 
  async pay(type:string = "all") {

    if (this.opts.useStripe) {

      this.stripePay(type);
    } else {
      this.easyPay(type);
    }

  }

  async stripePay(type:string = "all") {


    // let extraParams:any =   { customAmountAsDouble: this.data.customAmount };

    // if (this.opts.useBtapi) {
    //   let parentUrl = this.bas.ds.getValue(DATAID.APP_URL_QUERY_PARAMS).parentUrl || "";
    //   if(this.bas.envtest) console.log("BtPaymentComponent.parentUrl: " + parentUrl);

    //   extraParams.url = parentUrl;
    //   // return;
    // }
  
    this.data.loading = true;
    let json = await this.bas.ws.json({
      aType: "order",
      action: "getStripeSession",
      id: this.b.id, 
      type: type, 
      platform: "cbsite",
      customAmountAsDouble: this.data.customAmount 
     }, { showLoading: true }); 

    this.data.loading = false;
    if(this.bas.envtest) console.log("BtPaymentComponent.stripePay.json: ", json);




    // this.data.stripeAccessTokenPublic = json.stripeAccessTokenPublic;
    // this.data.stripeAccount = json.stripeAccount;

    this.stripe = Stripe(json.stripeAccessTokenPublic, {
        stripeAccount: json.stripeAccount
    });


    //TODO: støtte for this.opts.useBtapi (da må Stripe lastes inn på hovedsiden.)
    this.stripe.redirectToCheckout({
      // Make the id field from the Checkout Session creation API response
      // available to this file, so you can provide it as parameter here
      // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
      sessionId: json.stripeSessionId
    }).then((result:any) => {
      if(this.bas.envtest) console.log("BtPaymentComponent.stripePay.redirectToCheckout.result: ", result);
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    });

  }

  
  async easyPay(type:string = "all") {


    // if (this.opts.useBtapi) { //TEST

    //   if(this.bas.envtest) console.log("BtPaymentComponent.easyPay.opts.useBtapi");

    //   this.bas.es.sendParentMessage({
    //     action: "payment",
    //     checkoutKey: "checkoutKey-test",
    //     paymentId: "paymentId-test",
    //     dibsLang: "language-test",

    //   });
      


    //   return;
    // }

    let extraParams:any =   { customAmountAsDouble: this.data.customAmount };

    if (this.opts.useBtapi) {
      let parentUrl = this.bas.ds.getValue(DATAID.APP_URL_QUERY_PARAMS).parentUrl || "";
      if(this.bas.envtest || true) console.log("BtPaymentComponent.parentUrl: " + parentUrl);

      if (parentUrl.indexOf("%2F%2F") > 0) {
        parentUrl = decodeURIComponent(parentUrl);
        if(this.bas.envtest) console.log("BtPaymentComponent.decodeURIComponent.parentUrl: " + parentUrl);
      }
      extraParams.url = parentUrl;

      // "https:%2F%2Fbergensportsreiser.no%2Ftesting%2F%3Fcbapi-action%3Dpayment%26cbapi-option-id%3D155577%26cbapi-page%3Dorder%2Fpayment"
      // return;
    }
  
    this.data.loading = true;
    let json = await this.bas.os.easyPay(
      this.b.id, 
      type, 
      extraParams
    );
    this.data.loading = false;
    if(this.bas.envtest) console.log("BtPaymentComponent.easyPay.json: ", json);




    this.data.checkoutKey = json.checkoutKey;
    this.data.paymentId = json.paymentId;

    this.initEasyCheckout();
  }

  // async test() {
  //   let json = await this.bas.os.easyPay(126818, "custom", { customAmount: 5500 });
  //   if(this.bas.envtest) console.log("BtPaymentComponent.test.json: ", json);

  //   this.data.checkoutKey = json.checkoutKey;
  //   this.data.paymentId = json.paymentId;

  //   this.initCheckout();
  // }
}

